import React from 'react'
import styled from 'styled-components'
import MenuItems from './MenuItems'


const MainMenu = () => {
    return(
        <StyledMenu>
          <MenuItems/>
        </StyledMenu>
    );
}

export default MainMenu

const StyledMenu = styled.div`
  .nav-link{
    padding: 0 0;
  }

  .nav{
    width:100%;
    position: absolute;
    top:90%;
    justify-content:center !important;
  }

  .nav > a, .dropdown, .dropdown-menu > a{
    text-align:center;
    width:19%;
    background-color: var(--orange);
    font-size: .5em;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    font-weight: bold;
    box-shadow: 7px 6px 8px 5px rgba(0,0,0,0.3);
    z-index:2;
    border: 1px solid black;
  }

  .dropdown-menu{
    background-color:var(--orange);
    top:0 !important;
    bottom: auto !important;
    transform: translate(0,40%) !important;
    width:100%;
    padding: 0 0;
  }
  .dropdown-menu > a{
    color:white;
    width:100%;
    padding: 0 0;
    height:auto;
    font-size: 1em;
    padding-top: 9%;
    padding-bottom: 9%;
    border: 1px solid black;
    white-space:normal;
  }

  .dropdown-menu.show > a{
    box-shadow: none;
  }

  .nav a:hover, .dropdown:hover{
    background-color: var(--blue);
  }

  .activetopMenu{
    background-color: var(--blue);
  }

  @media only screen  and (max-width: 1483px){
    .dropdown-menu{
      transform: translate(0,80px) !important;
    }
   }
   @media only screen  and (max-width: 1087px){
    .dropdown-menu{
      transform: translate(0,75px) !important;
    }
   }
  @media only screen and (max-width: 992px){
    .nav{
      display:none;
    }
  }
`