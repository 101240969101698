import React from 'react'
import styled from 'styled-components'

const BurgerIcon =() =>{
    return(
        <HamburgerBox>
            <HamburgerInner/>
        </HamburgerBox>
    );
};

export default BurgerIcon

const HamburgerBox = styled.span`
    width: 35px;
    height: 24px;
    display: inline-block;
    position: relative;
`
const HamburgerInner = styled.span`
    width: 100%;
    height: 3px;
    background-color: white;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color .1s .2s ease-in-out;

    ::before, ::after{
        width: 100%;
        height: 3px;
        background-color: white;
        position: absolute;
        content: '';
        left: 0;
        transition: transform .2s .2s ease-in-out;
    }

    ::before{
        top: -10px;
    }
    ::after{
        top: 10px;
    }
`;