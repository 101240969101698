import React from 'react'
import {Link} from 'gatsby'

import Styled from 'styled-components'
import {Nav, NavDropdown} from 'react-bootstrap'

export default () =>{
  return(
    <Nav className="justify-content-end" >
      <Link to="/" className="nav-link">Home</Link>
      <NavDropdown title="Oferta" id="collasible-nav-dropdown">
        <Link to="/hale" className="dropdown-item">Hale</Link>
        <Link to="/zewnetrzne" className="dropdown-item">Betony zewnętrzne</Link>
        <Link to="/dom" className="dropdown-item">Dom</Link>
      </NavDropdown>
      <Link to="/gallery" className="nav-link">Galeria</Link>
      <Link to="/contact" className="nav-link">Kontakt</Link>
      <SpecialStyledLink to="/career" className="nav-link">Współpraca</SpecialStyledLink>
    </Nav>
  );
}

const SpecialStyledLink = Styled(Link)`
    background-color: var(--blue) !important;

    :hover{
        background-color: var(--main-bg-color) !important;
    }

`