import React from 'react'
import styled from 'styled-components'
import {Container, Row, Image} from 'react-bootstrap'
import Sprawdz from './Sprawdz'

import logoRF from '../../images/rzetelna_firma.png'

const RzetelnaFirma = () => {
  return(
    <Container>
      <Row className="d-flex justify-content-center">
        <Img src={logoRF} alt="Rzetelna Firma"/>
      </Row>
      <Row>
        <Sprawdz />
      </Row>
    </Container>
    
  )
}
export default RzetelnaFirma

const Img = styled(Image)`
  margin-bottom:15%;
  width:515px;
  height:220px;
  margin-left:15px;
  margin-top: 33px;
  @media only screen and (max-width:992px){
    max-width: 100vw;
    width:345px;
    height:147px;
  } 
`;