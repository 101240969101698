import React from "react"
import styled from 'styled-components'
import {Container, Row, Col} from 'react-bootstrap'

export default () => {
    return(
        <StyledFooter >
            <H3>KONTAKT</H3>
            <Container>
                <Row >
                    <Col sm={4} >504 044 591 - Wojtek Marszałkowski</Col>
                    <Col sm={4} >509 655 424 - Jacek Marszałkowski</Col>
                    <Col  sm={4}>e-mail: biuro@jastrych.com.pl</Col>
                </Row>
                <Row className="justify-content-center m-3">
                    <Col  sm={4} className="text-sm-right">80-298 Gdańsk, ul. Fundamentowa 2b/14</Col>
                    <Col sm={4} className="text-sm-left">NIP 9570873250, Regon 193001923</Col>
                </Row>
            </Container>
            <Hr />
            <span style={{fontSize:"0.7rem"}}><i>@Jakub Awieruk&Hubert Hałun</i></span>
        </StyledFooter>
        )
}

const StyledFooter = styled.footer`
    position: relative;
    background-color: rgb(25,25,25);
    text-align: center;
    padding-top: 1%;
    width:100%;
`;
const H3 = styled.h3`
    font-weight: 700;
    padding: 1rem;
`;

const Hr = styled.hr`
    width: 80%;
    border-style: dashed;
    border-top-width: 1px;
    border-spacing: 3px;
    border-color: rgb(54,54,54);
    margin-top: 2rem;
    margin-bottom: 0;
    padding-top: 1.5rem;
`;
