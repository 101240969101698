import React from 'react'
import {Navbar} from 'react-bootstrap'
import Styled, { createGlobalStyle } from 'styled-components'
import BurgerIcon from './BurgerIcon'
import MenuItems from './MenuItems'
import logo from '../images/logos/logo_horizontal_white.svg'

class HamburgerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state={isOpen:false};
};

  setWrapperRef(node) {
    this.wrapperRef = node;
  };

  handleClickOutside(e) {
    e.stopPropagation();

    if(this.state.isOpen){
    }
    if (this.wrapperRef && !this.wrapperRef.contains(e.target) && this.state.isOpen){
      
      this.setState({isOpen:false});
      
    }
  };

  componentDidUpdate(){
    if(this.state.isOpen){
      document.addEventListener('mousedown', this.handleClickOutside);
      
    } else {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  handleClick(){
    this.setState({isOpen: !this.state.isOpen});
  }

  render(){
    return(
        <StyleMenu >
            <NewToogleIcon/>
            <Navbar collapseOnSelect expand="true" variant="dark" ref={this.setWrapperRef}>
              <Navbar.Brand href="/"><img src={logo} alt="jastrych"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={this.handleClick.bind(this)}>
                  <BurgerIcon />
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav" className={(this.state.isOpen ? "d-block" : "d-none")} >
                  <MenuItems />
                </Navbar.Collapse>
            </Navbar>
        </StyleMenu>
    );
  }
}

export default HamburgerMenu

const StyleMenu = Styled.div`
  img{
    width:25%;
  }

  button{
    display:none;
  }

  .navbar-brand{
    width:85%;
    position: relative;
    display:block;
    margin-left:auto;
    margin-right:auto;
    padding: 0 0;
  }

  .dropdown-menu{
    background-color:var(--orange);
    width:100%;
    padding: 0 0;
    text-align:left;
    border: 0;
  }

  .nav-link{
    color: white !important;
    border: 1px solid black;
  }

  .dropdown-menu > a{
    background-color:var(--main-bg-color);
    border: 1px solid black;
    padding-top: 9%;
    padding-bottom: 9%;
    font-size:1.5rem;
    font-weight: 500;
    white-space:normal;

  }
  @media only screen and (max-width: 992px){
    img{
      width: 70%;
    }
    button{
      display: inline-block;
    }

    .navbar{
      padding: 0 0;
      flex-wrap: nowrap;
    }

    .navbar-brand{
      
    }
    .navbar-collapse, .navbar-nav{
      top:100%;
      position:absolute;
      background-color:var(--orange);
      width:100%;
      margin-left:auto;
      margin-right:auto;
    }
  }
`

const NewToogleIcon = createGlobalStyle`

    .navbar-toggler{
      padding: 10px;
      display: inline-block;
      cursor: pointer;
      background-color: transparent;
      border: 0;
      margin: 0;
    }
`;