import React from "react"
import styled from 'styled-components'
import HamburgerMenu from './hamburgerMenu'
const Header = () => {
  return(
    <StyledHeader >
        <HamburgerMenu/>
    </StyledHeader>
  );
}
export default Header

const StyledHeader =styled.header`
  position: relative;
  z-index: 5;
  background-color: var(--main-bg-color);
  text-align: center;
  font-weight: 700;
  font-size: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0px 5px 5px rgba(10,10,10,0.9);
  width:100%;
`;

