import React from 'react'
import Styled from 'styled-components'

const Sprawdz = () =>{
    return(
        <StyledLink target="_blank" href="http://wizytowka.rzetelnafirma.pl/6WL7RNG1/1" rel="noopener noreferrer">
            SPRAWDŹ!
        </StyledLink>
    );
}
export default Sprawdz;

const StyledLink = Styled.a`
    text-align: center;
    display:block;
    margin-left: auto;
    margin-right: auto;
    color:white;
    background-color: black;
    font-size: 1.5rem;
    font-weight: 700;
    width:100%;
    padding: 10px 0 10px 0;

`
