import React from 'react'
import Styled from 'styled-components'

const NapiszDoNas = () =>{
    return(
        <StyledLink href="mailto:biuro@jastrych.com.pl">
            NAPISZ DO NAS!
        </StyledLink>
    );
}

export default NapiszDoNas; 

const StyledLink = Styled.a`
    display:block;
    width:100%;
    background-color: var(--orange);
    color: white;
    text-align:center;
    font-weight: bold;
    font-size: 1.6em;
    padding: 10px 0 10px 0;
`