import Styled from 'styled-components'

export const StyledHeader = Styled.h2`
    font-weight: 500;
    font-size: 34px;
    text-align: center;
    padding-bottom: 2rem;
`

export const StyledCategory = Styled.p`
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    font-family: 'Lato', sans-serif;
    text-align: left;
`

export const StyledContent = Styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 72px;
`

export const StyledContainer =Styled.div`
    max-width: 1920px;
    margin-left:auto;
    margin-right:auto;
`

export const ContentWrapper = Styled.main`
    background-color: var(--main-bg-color);
    text-align: center;
    padding-top: 7%;
    

    @media only screen and (max-width: 992px){
        padding-top: 0;
    }
`;

export const ImageTextContainer = Styled.div`
    position: relative;
    text-align: center;
    color: white;
    font-size: 3em;
    font-weight: bold;
    width:100%;
    max-width: 1920px;

    @media only screen and (max-width:992px){
        height:51vh;
    }
`;

export const BannerImg = Styled.figure`
    width:100%;
    overflow:hidden;

    img{
        display:block;
        width:100%;
        height:auto;
        margin:-14.5% 0;

    }

    @media only screen and (max-width:992px){
        height: 50vh;
        width:auto;
        img{
            object-fit:cover;
            height:50vh;
            margin:0 0;
        }

    }
`;